<template>
  <div class="wrapper-main__content">
    <div class="form-content">
      <div class="d-flex flex-wrap py-2 border-bottom">
        <!--TITLE-->
        <VocabularyTitle :title="$t('holdings.holdings')" :per="paginationData.per_page" :entries="paginationData.total" />
        <!--TITLE-->
        <PaginateWrapper v-if="paginationData" :last-page="paginationData.last_page" :current="filterPage" class="ml-auto" @pageClick="pageClick" />

        <!--Search-->
        <div class="d-flex justify-content-between mt-3 w-100">
          <div class="d-flex gap-3">
            <search-dropdown v-model="selectedPerPage" @onSelect="pageClick(1)" />
            <!-- <b-form-checkbox v-if="canRestore" @input="pageClick(1)" v-model="filterShowTrashed" class="mt-2" switch>
                {{$t('table.showOnlyTrashed')}}
              </b-form-checkbox> -->
          </div>
          <search-input v-model="searchElements" @searchTable="pageClick(1)" />
        </div>

        <!--Search-->
      </div>

      <!--TABLE-->
      <div class="vocabulary-table">
        <div class="table-header sticky-vocabulary-header">
          <div class="table-header-text justify-content-start" style="flex-basis: 70%">
            <sort-arrows :sort-string="sortString" :column="'name,'" @click="sortTable('name,', $event)" />
            {{ $t('holdings.holdings') }}
          </div>
          <div class="table-header-text justify-content-start" style="flex-basis: 30%"></div>
        </div>

        <SpinnerLoader :loading="holdingsStatus" />
        <DropDownTable
          v-for="row in tableData"
          :key="row.id"
          :row="row"
          :child-item="row.channels"
          edit-permission="holding.update"
          add-permission="false"
          delete-permission="holding.delete"
          edit-inner-permission="false"
          delete-inner-permission="false"
          @showModalAddChild="() => {}"
          @showModalEditChild="() => {}"
          @showModalDeleteChild="() => {}"
          @showModalEditParent="(data) => showModalHoldings('edit', data.data)"
          @showModalDeleteParent="(data) => showModalDeleteHoldings(data.data)"
        />
      </div>
      <!--TABLE-->

      <!--FOOTER-->
      <div class="input-pagin">
        <b-button v-if="canCreate" variant="primary" data-automatic="add-holding-btn" @click="showModalHoldings('add')">
          {{ $t('holdings.addNewHolding') }}
        </b-button>
      </div>
      <!--FOOTER-->

      <b-modal
        id="add-edit-holdings"
        ref="add-edit-holdings"
        size="xl"
        :title="modalType === 'add' ? $t('holdings.addNewHolding') : $t('holdings.editHoldings')"
        :ok-disabled="!$v.writeTitle.required"
        :ok-title="modalType === 'add' ? $t('table.add') : $t('table.edit')"
        :cancel-title="$t('table.cancel')"
        :busy="isModalBusy"
        @ok.prevent="checkIfValidThenEnter"
      >
        <SpinnerLoader v-if="isModalLoading" :loading="isModalLoading ? 'loading' : 'success'" />
        <form v-else @submit.stop.prevent="checkIfValidThenEnter">
          <input type="submit" value="Submit" class="hidden-submit" />
          <b-form-group :label="$t('table.writeTitle')" label-for="input-holding-name">
            <b-form-input
              id="input-holding-name"
              v-model.trim="$v.writeTitle.$model"
              type="text"
              :placeholder="$t('table.enterTitle')"
              autofocus
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group :label="$t('table.selectChannels')">
            <CoupleSelects
              :add-element.sync="selectAdd"
              :delete-element.sync="selectDelete"
              :add-options="options"
              :remove-options="value"
              @leftHandler="leftHandler()"
              @rightHandler="rightHandler()"
            />
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import VocabularyTitle from '@/components/VocabularyTitle';
import SpinnerLoader from '@/components/SpinnerLoader';
import errorsHandler from '@/utils/errorsHandler';
import PaginateWrapper from '../components/PaginateWrapper';
import SearchDropdown from '../components/SearchDropdown';
import SearchInput from '../components/SearchInput';
import SortArrows from '../components/SortArrows';
import sortTableMixin from '@/mixins/sortTable.js';
import adminPanelQuery from '@/mixins/adminPanelQuery.js';
import CoupleSelects from '@/components/CoupleSelects.vue';
import DropDownTable from '../components/DropDownTable';

export default {
  name: 'Holdings',
  components: { PaginateWrapper, SpinnerLoader, VocabularyTitle, SearchDropdown, SearchInput, SortArrows, CoupleSelects, DropDownTable },
  mixins: [sortTableMixin, adminPanelQuery],
  props: {
    q: { type: String, default: undefined },
    per_page: { type: [String, Number], default: undefined },
    on_page: { type: [String, Number], default: undefined },
    sort_mode: { type: String, default: undefined },
  },
  data() {
    return {
      tableData: [],
      paginationData: '',
      searchElements: '',
      writeTitle: '',
      value: [],
      options: [],
      filterPage: 1,
      selectedPerPage: '100',
      selectAdd: '',
      selectDelete: '',
      modalType: 'add',
      isModalLoading: true,
      isModalBusy: false,
      filterShowTrashed: false,
    };
  },
  validations: {
    writeTitle: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      holdings: 'getHoldings',
      holdingsStatus: 'getHoldingsStatus',
      channel: 'getChannels',
      channelsStatus: 'getChannelsStatus',
      modalEditHoldings: 'getModalEditHoldings',
    }),
    canEdit: function () {
      return this.$checkPermissions('holding.update');
    },
    canCreate: function () {
      return this.$checkPermissions('holding.create');
    },
    canDelete: function () {
      return this.$checkPermissions('holding.delete');
    },
  },
  watch: {
    holdings() {
      this.setTableData();
    },
  },
  created() {
    document.title = this.$i18n.t('holdings.holdings') + ' – OpenMediaLogic';
  },
  mounted() {
    this.getHoldingsInfo();
  },
  destroyed() {
    this.$store.commit('clearHoldingsList');
    this.$store.commit('clearChannelsList');
  },
  methods: {
    leftHandler() {
      if (this.selectAdd.id) {
        this.options = this.options.filter((option) => option.id !== this.selectAdd.id);
        this.value.unshift(this.selectAdd);
        this.selectAdd = '';
      }
    },
    rightHandler() {
      if (this.selectDelete.id) {
        this.value = this.value.filter((option) => option.id !== this.selectDelete.id);
        this.options.unshift(this.selectDelete);
        this.selectDelete = '';
      }
    },

    async paramsData() {
      if (this.tableData) this.tableData.length = 0;
      await this.$store.dispatch('GET_HOLDINGS', {
        page: +this.filterPage,
        per_page: this.selectedPerPage,
        'filter[name]': this.searchElements !== '' ? this.searchElements : null,
        sort: this.sortString ? this.sortString : null,
        'filter[trashed]': this.filterShowTrashed ? 'only' : undefined,
        include: 'channels',
      });
      this.updateQuery();
    },

    // pagination
    pageClick(page) {
      this.filterPage = page;
      this.paramsData();
    },
    // get table info
    setTableData() {
      if (this.holdings) {
        this.tableData = this.holdings.data;
        this.paginationData = this.holdings.pagination;
        if (this.filterPage > this.holdings.pagination.last_page) {
          this.filterPage = this.holdings.pagination.last_page;
          this.pageClick(this.filterPage);
        }
      }
    },
    async getHoldingsInfo() {
      await this.restoreFromQuery();
      this.paramsData();
    },

    async showModalHoldings(type, data) {
      this.isModalLoading = true;
      this.writeTitle = '';
      this.value = [];
      this.options = [];
      this.modalType = type;
      this.$bvModal.show('add-edit-holdings');
      await this.$store.dispatch('GET_CHANNELS', { per_page: 2000 });
      if (type === 'add') {
        this.options = this.channel.data.filter((option) => option.holding_id == null);
      } else {
        await this.$store.dispatch('GET_HOLDINGS_ID', data);
        this.options = this.channel.data.filter(
          (option) => option.holding_id == null && !this.modalEditHoldings.channels.find((value) => value.id === option.id)
        );
        this.writeTitle = this.modalEditHoldings.name;
        this.value = this.modalEditHoldings.channels;
      }
      this.isModalLoading = false;
    },

    async addEditHoldings() {
      this.isModalBusy = true;
      const name = this.writeTitle;
      const formData = {
        id: this.modalType === 'add' ? undefined : this.modalEditHoldings.id,
        name: this.writeTitle,
        channels: this.value.map((v) => v.id),
      };
      await this.$store.dispatch(this.modalType === 'add' ? 'POST_HOLDINGS' : 'PUT_HOLDINGS', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.modalType === 'add' ? this.$i18n.t('holdings.addedHolding') : this.$i18n.t('holdings.editedHolding'),
            text: name,
          });
          this.$bvModal.hide('add-edit-holdings');
          this.paramsData();
          this.writeTitle = '';
          this.value = [];
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.isModalBusy = false;
    },

    // modal delete
    async showModalDeleteHoldings(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('holdings.confirmDeleteHolding')} "${data.name}"?`, {
          title: this.$i18n.t('holdings.deleteHoldings'),
          size: 'sm',
          okTitle: this.$i18n.t('table.yes'),
          cancelTitle: this.$i18n.t('table.no'),
          autoFocusButton: 'ok',
          id: 'confirm-delete-modal',
        })
        .then((value) => {
          if (value) {
            const formData = data.id;
            const name = data.name;
            this.$store.dispatch('DELETE_HOLDINGS_ID', {
              formData,
              handler: () => {
                this.$notify({
                  type: 'success',
                  title: this.$i18n.t('holdings.deleteHoldings'),
                  text: name,
                });
                this.paramsData();
              },
              handlerError: (errors) => {
                errorsHandler(errors, this.$notify);
              },
            });
          }
        })
        .catch((err) => {});
    },

    checkIfValidThenEnter() {
      if (this.$v.writeTitle.required && !this.isModalBusy) {
        this.$bvModal
          .msgBoxConfirm(
            this.modalType === 'add'
              ? `${this.$i18n.t('holdings.confirmAddHolding')} "${this.writeTitle}"?`
              : `${this.$i18n.t('holdings.confirmEditHolding')} "${this.writeTitle}"?`,
            {
              title: this.modalType === 'add' ? this.$i18n.t('holdings.addHoldings') : this.$i18n.t('holdings.editHoldings'),
              size: 'sm',
              okTitle: this.$i18n.t('table.yes'),
              cancelTitle: this.$i18n.t('table.no'),
              autoFocusButton: 'ok',
              id: 'confirm-add-edit-holding-modal',
            }
          )
          .then((value) => {
            if (value) this.addEditHoldings();
          })
          .catch((err) => {});
      }
    },
  },
};
</script>

<style lang="sass"></style>
